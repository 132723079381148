$create-question-answer-input-gap: 1rem;
$create-question-answer-input-equal-full-widths: calc((100% / 3) - #{$create-question-answer-input-gap * (2/3)});

.create-question-answer-input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  &_answer-container {
    width: 100%;

    @include media-breakpoint-up(xxl) {
      width: $create-question-answer-input-equal-full-widths;
    }
  }

  &_point-container {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(30% - #{$create-question-answer-input-gap / 2})
    }

    @include media-breakpoint-up(xxl) {
      width: $create-question-answer-input-equal-full-widths;
    }
  }

  &_characteristic-container {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(70% - #{$create-question-answer-input-gap / 2})
    }

    @include media-breakpoint-up(xxl) {
      width: $create-question-answer-input-equal-full-widths;
    }
  }
}

.create-question-answer-input-remove-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  a {
    font-size: 0.9em;
    color: $red;
  }
}
