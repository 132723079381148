.icon-button-container {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;

  &_button {
    background-color: rgba($lightGray, 0.9);
    border-radius: 50%;
    cursor: pointer;
    @include flex(row, center, center);
    transition: all 0.2s;
    border: 1px solid transparent;

    &:hover {
      background-color: rgba($lightGray, 1);
      cursor: pointer;
      border-color: $darkBlue;

      .icon-button-container_button_icon {
        color: $darkBlue !important;
      }
    }

    &:active {
      background-color: rgba($darkGray, 0.2);
    }

    &_icon {
      @include svg-sizer(40%);
      transition: all 0.2s;
      color: rgba($darkGray, 0.7);
    }
  }
}

