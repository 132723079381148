.frame-one-round-panel {
  @extend .frame-shadow;
  width: 100%;
  background-color: $white;
  border-radius: 16px;
  overflow: hidden !important;

  border-style: solid;
  border-width: 1px;
  border-color: $lightGray;
  border-right-width: 5px;
  border-right-color: $darkPurple;
}
