a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: underline; }
a:active { text-decoration: none; }

p {
  @extend .miriam-libre;
}

span {
  @extend .miriam-libre;
}

button {
  @extend .miriam-libre;
}

label {
  @extend .miriam-libre_bold;
  font-size: 10pt;
  margin-bottom: 0.25rem !important;
  color: $darkGray;
  //letter-spacing: 1pt;
}
