$create-bundle-details-image-section-vertical-margin: 1.5rem;

.create-bundle-details {
  &_thumbnail-section {
    display: flex;
    justify-content: center;
    margin-top: $create-bundle-details-image-section-vertical-margin;
    margin-bottom: $create-bundle-details-image-section-vertical-margin;

    &_img-container {
      width: 100%;
      max-width: 500px;
      aspect-ratio: 16/9;
      border-radius: 8px;
      overflow: hidden;
      background-color: $lightGray;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  &_gallery-section {
    margin-top: $create-bundle-details-image-section-vertical-margin;
    margin-bottom: $create-bundle-details-image-section-vertical-margin;
  }
}
