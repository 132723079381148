.side-bar-button {
  overflow: hidden;
  border: none;
  background-color: transparent;

  @include flex(row, flex-start, center);
  width: 100%;
  padding: 0.4rem 1.25rem;
  color: rgba($highlightPurple, 0.7);
  border-radius: 7px;
  transition: all 0.2s;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: $highlightPurple;
    background-color: rgba($deepestPurple, 0.7);
  }

  &:active, &_active {
    color: $sidebar-white-adjuster;
    background-color: rgba($purple, 0.8);

    &:hover {
      color: $sidebar-white-adjuster;
      background-color: rgba($purple, 0.8);
    }
  }

  &:focus {
    outline: none !important;
  }

  svg {
    @include svg-sizer(1.4em);
    margin-right: 0.6em;
  }

  span {
    margin-top: 0.075rem;
  }
}
