.frame-one-radio {
  display: flex;

  &_real-label, label {
    font-size: initial !important;
    margin-bottom: initial !important;
    font-weight: normal !important;
    text-indent: 0 !important;

    cursor: pointer;
  }

  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.75;
  }

  &_disabled {
    cursor: default;
    opacity: 1 !important;

    label {
      cursor: default !important;
    }

    .frame-one-radio_real-label {
      opacity: 0.5;
      cursor: default !important;
    }
  }
}
