.login-project-info-label {
  @include flex(row, flex-start, center);
  align-self: flex-start;

  &_icon {
    @include svg-sizer(28px);
    margin-top: -0.08rem;
    margin-right: 0.7rem;
  }

  &_text-container {
    @include flex(column);
    font-size: 10pt;

    &_name {
      font-weight: bold;
    }

    &_version {
    }
  }
}
