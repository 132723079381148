.admin-page-header {
  background-color: rgba($mediumGray, 0.35);

  &_container {
    min-height: 80px;
    @include flex(row, flex-end, center);
    padding-top: $admin-nav-bar-padding;
    padding-bottom: $admin-nav-bar-padding;
    transition: all 0.2s;
    position: relative;

    &_info {
      transition: all 1s;
      position: absolute;
      top: $admin-nav-bar-padding;

      &_hidden {
        user-select: none;
        opacity: 0;
        right: 60px;
      }

      &_visible {
        user-select: initial;
        opacity: 1;
        right: $admin-nav-bar-padding;
      }
    }
  }
}
