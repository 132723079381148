.social-icon-button {
  cursor: pointer;
  color: white;
  text-decoration: none !important;
  transition: all 0.25s;

  &:hover {
    opacity: 0.7;
  }

  svg {
    @include svg-sizer(18px);
  }
}
