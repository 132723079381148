.btn {
  &-thin {
    height: 42px;
    margin-bottom: .25rem;
  }

  svg {
    @include svg-sizer(1.2em);
    margin-right: 0.6em;
  }

  &-link {
    outline: none !important;
    box-shadow: none !important;
  }

  &-darkPurple {
    &:hover {
      background-color: $purple;
      border-color: $purple;
    }
  }

  &-outline-darkPurple {
    &:hover {
      background-color: rgba($purple, 0.1);
      color: $darkPurple;
    }
  }

  &-darkBlue {
    &:hover {
      background-color: $lightBlue;
      border-color: $lightBlue;
    }
  }

  &-outline-darkBlue {
    &:hover {
      background-color: rgba($lightBlue, 0.1);
      color: $lightBlue;
    }
  }

  &-outline-mediumGray {
    &:hover {
      border-color: $darkBlue;
      background-color: white;
    }
  }

  &-danger {
    background-color: #FE4343;
    border-color: #9D0311;

    &:hover {
      background-color: #FE6969;
      border-color: #F70A1C;
    }
  }
}


