.frame-one-button {
  @include flex(row, center, center);

  &-small {
    margin-right: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;

    &:last-of-type {
      margin-right: 0;
    }

    svg {
      margin: 0;
    }
  }
}
