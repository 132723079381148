.frame-one-react-select {
  div[class*="-control"] {
    height: $input-height;
    border-color: $input-border-color;
    border-width: $input-border-width;
    border-radius: $input-border-radius;

    &:focus {
      background-color: red;
    }
  }

  div[class*="-placeholder"] {
    color: $input-placeholder-color;
    font-weight: normal;
  }

  div[class*="-ValueContainer"] {
    white-space: nowrap;
    padding-left: $input-padding-x;
  }
}
