$label-outline-vertical-padding: 6px;
$label-outline-horizontal-padding: 10px;
$label-outline-font-size: 0.8rem;

.label-outline-container {
  @include flex(row);

  &_inner {
    color: rgba($lightBlue, 0.7);
    background-color: $glowerBlue;
    border: 1px solid rgba($lightBlue, 0.5);
    border-radius: 5px;
    padding: $label-outline-vertical-padding $label-outline-horizontal-padding;
    font-size: $label-outline-font-size;
    line-height: $label-outline-font-size;
    transition: all 0.2s;
    @include flex(row, flex-start, center);

    &_clicker {
      &:hover {
        cursor: pointer;
        background-color: rgba($lightBlue, 0.15);
        color: rgba($lightBlue, 0.9);

        .label-outline-container_inner_icon {
          color: rgba($lightBlue, 0.9);
        }
      }
    }

    &_icon {
      @include svg-sizer($label-outline-font-size);
      margin-left: 8px;
      margin-top: -1px;
      transition: all 0.2s;
    }
  }
}

