h1 {
  @extend .miriam-libre_bold;
  font-size: 36pt;
}

h2 {
  @extend .miriam-libre_bold;
  font-size: 30pt;
}

h3 {
  @extend .miriam-libre_bold;
  font-size: 24pt;
}

h4 {
  @extend .miriam-libre_bold;
  font-size: 18pt;
}

h5 {
  @extend .miriam-libre_bold;
  font-size: 14pt;
}

h6 {
  @extend .miriam-libre_bold;
  font-size: 11pt;
}
