@import "colors.modules";

/* Breakpoints */
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px, // for pages with the side bar, apply same col sizes for both md & lg, and perhaps evel xl.
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
);

$container-max-widths: (
        sm: 540px,
        md: 780px,
        lg: 960px,
        xl: 1140px,
        xxl: 1200px,
        xxxl: 1240px,
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/* Colors */
$custom-colors: (
        "white": $white,
        "offWhite": $offWhite,
        "darkPurple": $darkPurple,
        "purple": $purple,
        "darkBlue": $darkBlue,
        "lightBlue": $lightBlue,
        "glowBlue": $glowBlue,
        "lightGray": $lightGray,
        "mediumGray": $mediumGray,
        "red": $red,
        "green": $green,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

/* Buttons */
$btn-padding-y: 0.5rem;
$btn-padding-x: 2rem;
$btn-font-size: 0.85rem;

$btn-border-width: 2px;

$btn-border-radius: 8px;

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    color: $offWhite !important;
  }

  &-outline-#{$color} {
    @include button-outline-variant($value);
    &:hover {
      color: $offWhite !important;
    }
  }
}

/* Inputs */
$input-padding-x: 1.1rem;
$input-padding-y: 0.5rem;
$input-font-size: 0.95em;

$input-bg: $white;
$input-disabled-bg: $lightGray;

$input-color: $darkPurple;
$input-plaintext-color: $darkPurple;
$input-border-color: $mediumGray;
$input-border-width: 1px;
$input-box-shadow: none;

$input-border-radius: 8px;

$input-focus-border-color: $darkBlue;
$input-focus-color: $darkPurple;
$input-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba($darkBlue, .25);

$input-placeholder-color: $mediumGray;
$input-height: 41px;

@import "~bootstrap";

/* Inputs */
input {
  &:hover {
    border-color: $lightBlue;
  }
}

textarea {
  min-height: 140px !important;
  max-height: 420px;
  padding: 0.475rem 1.1rem !important;
}

/* Custom Checkboxes */
@import '~pretty-checkbox/src/scss/variables';
$pretty--colors: $theme-colors;
@import "~pretty-checkbox/src/pretty-checkbox.scss";
