$frame-one-modal-padding: 2rem;

.modal-content {
  border: none !important;
}

.frame-modal {
  &_header {
    padding: $frame-one-modal-padding;
    background-color: $lightGray;
    border-bottom: 1px solid rgba($mediumGray, 0.5);

    &_title-row {
      @include flex(row, space-between);

      &_title {
        margin-bottom: 0;
        word-break: break-word;
      }

      &_close-icon {
        @include svg-sizer(24px);
        cursor: pointer;
        margin-left: 1.5rem;
        transition: all 0.2s;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    &_children {
      padding-top: $frame-one-modal-padding;
    }
  }

  &_body {
    width: 100%;
    padding: $frame-one-modal-padding;
  }

  &_footer {
    width: 100%;
    padding: $frame-one-modal-padding;
    padding-top: 0;
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
