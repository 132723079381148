.profile-picture {
  aspect-ratio: 1;
  background-color: $darkBlue;
  border-radius: 50%;
  overflow: hidden;
  @include flex(row, center, center);
  transition: all 0.2s;

  &_clicker {
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_placeholder {
    @include svg-sizer(30%);
    color: $offWhite;
  }
}
