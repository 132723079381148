$create-bundle-additional-images-gallery-gap: 1rem;

.create-bundle-additional-images-gallery {
  width: 100%;
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $create-bundle-additional-images-gallery-gap;

  &_item {
    @include flex-row-item-width-helper($create-bundle-additional-images-gallery-gap, 4);
  }
}
