.login-footer {
  @include flex(row, space-between);
  color: $white;

  &_project-info {

  }

  &_frame-one {

  }

  @media screen and (max-width: $login-breakpoint-c) {
    @include flex(column);
    padding: 0 1rem;

    &_project-info {
      margin-bottom: 2rem;
    }
  }
}
