.section-label {
  &_title {
    margin-bottom: 0;
    color: rgba($darkGray, 0.8);

    @include media-breakpoint-down(lg) {
      margin-bottom: 0.5rem;
    }
  }
}
