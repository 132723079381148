.make-image-cell {
  width: 44px;
  height: 44px;
  overflow: hidden;

  &_company-profile-picture {
    border-radius: 50%;
  }
}

.manage-bundles-image-cell {
  width: 100px;
  aspect-ratio: 16/9;
  overflow: hidden;

  &_company-profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
