@import "colors.modules";

/* Sidebar variables; used in a couple of css files & in the tsx files */
$sidebarVisibleMinWidth: 992px;
$sidebar-section-padding: 1.75rem;
$sidebar-white-adjuster: rgba($offWhite, 0.85);
$sidebar-highlight-purple-adjuster: rgba($highlightPurple, 0.5);

/* Page Layout & Containers */
$admin-nav-bar-padding: 1.25rem;
$page-containers-padding: 2.5rem;

:export {
  sidebarVisibleMinWidth: $sidebarVisibleMinWidth;
}
