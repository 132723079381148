.bread-crumb-container {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
  opacity: 0.75;

  &_item {
    @include flex(row, flex-start, center);
    font-size: 0.9em;

    &_icon {
      @include svg-sizer(1em);
      color: $darkPurple;
      margin-right: 0.2rem;
    }

    &_link {
      color: $darkPurple;

      &:hover {
        color: $darkPurple;
      }
    }
  }
}
