$white: #FFFFFF;
$offWhite: #FAFAFA;

$darkPurple: #0C0924;
$purple: #343366;
$highlightPurple: #9B9EBA;
$deepestPurple: #201B43;

$darkBlue: #0F49AC;
$lightBlue: #1479DD;
$glowBlue: #BBDFFD;
$glowerBlue: #E3F3FE;

$lightGray: #EEEEEE;
$mediumGray: #9E9E9E;
$darkGray: #616161;

$red: #D00000;
$green: #58A349;

$login-theme: rgba($purple, 0.5);

:export {
  white: $white;
  offWhite: $offWhite;

  darkPurple: $darkPurple;
  purple: $purple;
  highlightPurple: $highlightPurple;
  deepestPurple: $deepestPurple;

  darkBlue: $darkBlue;
  lightBlue: $lightBlue;
  glowBlue: $glowBlue;
  glowerBlue: $glowerBlue;

  lightGray: $lightGray;
  mediumGray: $mediumGray;
  darkGray: $darkGray;

  red: $red;
  green: $green;
}
