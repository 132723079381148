.login-background {
  background-image: url("/images/login-background.jpg");
  background-size: cover;
}

/* Login 2 */

$login-breakpoint-a: 860px;
$login-breakpoint-b: 490px;
$login-breakpoint-c: 380px;

$login-container-max-width: 906px;
$login-container-horizontal-padding: 2rem;

.login-page {
  position: relative;
  //background-color: $darkPurple;
  background-color: $darkPurple;

  &_content {
    @include flex(column, space-between, center);

    min-height: 100vh;
    position: relative;

    &_spacer {
      height: 3.5rem;
      width: 100%;
    }

    &_container {
      width: 100%;
      max-width: calc(#{$login-container-max-width} + calc(#{$login-container-horizontal-padding * 2}));
      padding: 0 $login-container-horizontal-padding;
    }
  }

  &_background-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    @include flex(column);

    &_graphic-container {
      height: 50%;
      background-color: $login-theme;
      overflow: hidden;

      &_graphic {
        width: 100%;
        mix-blend-mode: luminosity;
      }
    }

    &_solid {
      height: 50%;
    }
  }

  @media screen and (max-width: $login-breakpoint-a) {
    &_content {
      &_container {
        padding: 0 1.5rem;
      }
    }
  }

  @media screen and (max-width: $login-breakpoint-b) {
    &_content {
      &_container {
        padding: 0 0.5rem;
      }
    }
  }
}
