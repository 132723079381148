.demo-page {
  &_misc {
    &_tags-container {
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.75rem;
      align-items: flex-start;
    }
  }

  &_modal-table-color-container {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    flex-direction: row;

    .label-outline-container {
      margin-right: 0.5rem;
    }
  }
}
