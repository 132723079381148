.frame-one-card {
  width: 100%;
  background-color: $white;

  /* Removed because it interferes with custom drop-down components. Not sure why it was here in the first place...  */
  //overflow: hidden !important;

  border-style: solid;
  border-width: 1px;
  border-color: rgba($mediumGray, 0.2);
  padding: 1.5rem;

  @include media-breakpoint-down(xs) {
    padding: 1.1rem;
  }
}
