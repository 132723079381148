.create-bundle-selected-characteristic {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  &_pic-and-name {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    &_picture-container {
      display: flex;
      align-items: center;

      &_sizer {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid $mediumGray;
        background-color: $lightGray;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &_name-container {
      display: flex;
      align-items: center;
    }
  }

  &_values-and-delete {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    &_val-col {
      display: flex;
      align-items: center;
    }

    &_delete-container {
      display: flex;
      align-items: center;
    }
  }
}
