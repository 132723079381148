.profile-picture-vertical {
  width: 100%;
  @include flex(column, initial, center);

  &_image-container {
    height: 3.75rem;
    margin-bottom: 0.5rem;
  }

  &_label {
    margin-bottom: 0.1rem;
    font-size: 0.9em;
    opacity: 0.75;
    text-align: center;
  }
}
