.add-new-characteristic-modal {
  &_container {
    @include flex(column, initial, center);
    background-color: #F5F5F5;

    &_form {
      @include container-form-width();
      padding: 1rem;
      border: 1px solid #E0E0E0;
      background-color: #FFFFFF;

      .label-with-icon {
        svg {
          color: #3B2CB0;
        }
      }

      .phone-number-helper-text {
        font-size: 12px;
      }

      &_sub-header {
        border-bottom: 1px solid #E0E0E0;
      }
    }

    &_footer {
      @include container-form-width();
      border-top: 1px solid #E0E0E0;
    }
  }
}
