$login-footer-socials-spacer: 0.35rem;

.login-frame-one-info {
  @include flex(column, initial, flex-end);

  &_f1-icon {
    @include svg-sizer(57px);
    margin-bottom: 1.2rem;
  }

  &_text-container {
    @include flex(column);
    font-size: 10pt;
    margin-bottom: 1.2rem;

    &_powered-by {
      text-align: right;
    }

    &_frame-one {
      text-align: right;
      font-weight: bold;
    }
  }

  &_icons-container {
    .social-icon-button {
      padding: 0 $login-footer-socials-spacer;

      &:first-of-type {
        padding-right: calc(#{$login-footer-socials-spacer} * 1.75);
      }

      &:last-of-type {
        margin-right: calc(#{$login-footer-socials-spacer} * -1);
      }

      svg {
        @include svg-sizer(16px);
      }
    }
  }
}
