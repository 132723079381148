@import "bootstrap-override"; // colors here
@import "variables.module";
@import "mixins";
@import "fonts";
@import "pages";
@import "components";
@import "table";
@import "misc";
@import "modals";
@import "buttons";
@import "inputs";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
