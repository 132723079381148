.sidebar {
  width: 290px;
  height: 100vh;
  overflow-y: auto;
  background-color: $darkPurple;

  &_close-icon-container {
    @include flex(row, flex-end);
    margin: 1rem;
    margin-bottom: 0.5rem;

    &_x {
      @include svg-sizer(1.5rem);
      transition: all 0.2s;
      color: $offWhite;

      &:hover {
        cursor: pointer;
        color: rgba($offWhite, 0.8);
      }
    }
  }

  &_inner {
    @include flex(column, space-between);
    width: 100%;
    height: 100%;

    &_content {
      &_title-container {
        padding: $sidebar-section-padding;

        &_title {
          color: $sidebar-white-adjuster;
          margin-bottom: 0;
        }
      }

      &_version-info-container {
        padding: $sidebar-section-padding;
        background-color: $deepestPurple;
        border-width: 0;
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: $sidebar-highlight-purple-adjuster;
      }

      .side-bar-version-display {
        &_label {
          color: $highlightPurple;
          text-transform: uppercase;
          margin-bottom: 1rem;
        }

        &_name {
          color: $sidebar-white-adjuster;
          font-weight: bold;
          margin-bottom: 0;
        }

        &_version {
          color: $sidebar-white-adjuster;
          margin-bottom: 0;
        }
      }

      &_button-list-container {
        @include flex(column);
        padding: 1rem 0.5rem;

        &_link {
          text-decoration: none !important;
        }

        &_button {
          margin-bottom: 0.35rem;
        }
      }
    }

    &_frame-one-info {
      color: $sidebar-white-adjuster;
      padding: $sidebar-section-padding;
      border-width: 0;
      border-top-width: 1px;
      border-style: solid;
      border-color: $sidebar-highlight-purple-adjuster;
    }
  }
}

.sidebar-header-toggle-icon-container {
  background-color: rgba($mediumGray, 0.35);
  @include flex(row, flex-end);
  padding: 1rem;

  @media screen and (min-width: $sidebarVisibleMinWidth) {
    display: none !important;
  }

  &_hamburger {
    @include svg-sizer(1.5rem);
    transition: all 0.2s;
    color: $darkPurple;

    &:hover {
      cursor: pointer;
      color: $purple;
    }
  }
}

@keyframes pulsate-sidebar-icon {
  0% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(0.85) rotate(-12deg);
  }
  50% {
    transform: scale(1) rotate(0deg);
  }
  75% {
    transform: scale(0.85) rotate(12deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.custom-sidebar-nav-classes {
  &_demo-page-animator {
    svg {
      animation: pulsate-sidebar-icon 4.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }
  }
}
