.side-bar-frame-one-social-info {
  @include flex(column, initial, center);

  &_f1-icon {
    @include svg-sizer(57px);
    margin-bottom: 1.4rem;
  }

  &_text-container {
    @include flex(column);
    font-size: 10pt;
    margin-bottom: 1.2rem;

    &_powered-by {
      text-align: center;
    }

    &_frame-one {
      text-align: center;
      font-weight: bold;
    }
  }
}
