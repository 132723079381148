$login-icon-size: 2.4em;

.login-card {
  display: flex;
  flex-direction: row;
  padding: 0 !important;

  &_form {
    width: 50%;
    padding: 3rem;

    &_user-icon {
      @include svg-sizer($login-icon-size);
      margin-bottom: 0.5rem;
    }

    &_title {
      margin-bottom: 1.5rem;
    }

    input {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &_button-container {
      @include flex(row, flex-end);
      margin-top: 2rem;

      .btn {
        width: 50%;
      }
    }

    &_contact {
      margin-top: 2.5rem;
      margin-bottom: 0;
      color: $darkGray;

      a {
        @extend .text-purple;
        font-weight: bold;
      }
    }
  }

  &_graphic-container {
    width: 50%;
    position: relative;
    overflow: hidden;

    &_bubbles {
      object-fit: cover;
      position: absolute;
      top: -90px;
      left: 46px;
      opacity: 0.75;
      user-select: none;
      -webkit-user-drag: none;
    }

    &_computer-container {
      position: relative;
      width: 100%;
      height: 100%;
      @include flex(column, center);

      &_computer {
        width: 100%;
        margin-left: 40px;
      }
    }
  }

  @media screen and (max-width: $login-breakpoint-a) {
    &_form {
      width: 100%;

      &_button-container {
        @include flex(row, flex-end);
        margin-top: 2rem;

        .btn {
          width: 100%;
          max-width: 220px;
        }
      }
    }

    &_graphic-container {
      display: none !important;
    }
  }

  @media screen and (max-width: $login-breakpoint-b) {
    &_form {
      padding: 1.5rem;

      &_title {
        font-size: 18pt;
      }
    }
  }

  @media screen and (max-width: $login-breakpoint-c) {
    &_form {
      padding: 1.2rem;

      &_button-container {
        .btn {
          width: 100%;
          max-width: 100%;
        }
      }

      &_contact {
        font-size: 11pt;
        text-align: center;
      }
    }
  }
}
