.company-card-2 {
  padding: 1rem;
  background-color: white;
  border: 2px solid $lightGray;
  border-radius: 2px;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    flex-direction: column;
    justify-content: normal;
    align-items: normal;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_info {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    gap: 1rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(sm) {
      gap: 1.5rem;
      padding-right: 1.5rem;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      padding-right: 0;
      margin-bottom: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 1.5rem;
      margin-bottom: 0;
    }

    &_image-spacer {
      display: flex;
      align-items: center;

      &_img {
        width: 60px;
        aspect-ratio: 1;
        border-radius: 50%;

        @include media-breakpoint-up(sm) {
          width: 80px;
        }

        @include media-breakpoint-up(md) {
          width: 100px;
        }
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &_title {
        margin-bottom: 0.25rem;
      }

      &_subtitle {
        margin-bottom: 0;
        color: $mediumGray;
      }
    }
  }

  &_button-container {
    display: flex;
    justify-content: center;

    .btn {
      white-space: pre;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
