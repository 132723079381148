@import url("https://fonts.googleapis.com/css2?family=Miriam+Libre:wght@400;700&display=swap");

@mixin miriam-libre($font-weight: 400) {
    font-family: "Miriam Libre", sans-serif !important;
    font-style: normal;
    font-weight: $font-weight !important;
}

.miriam-libre {
    @include miriam-libre;
}

.miriam-libre_regular {
    @include miriam-libre(400);
}

.miriam-libre_bold {
    @include miriam-libre(700);
}
