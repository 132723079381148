.frame-one-paginator {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  &_limiter-container {
    &_limiter {

    }
  }

  &_buttons-container {

    &_buttons {
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;
      justify-content: center;

      &_button {
        @include flex(row, center, center);
        font-size: 0.9em;
        min-width: 32px;
        padding: 0.25rem 0.6rem;
        border: 1px solid $lightGray;
        cursor: default !important;
        transition: all 0.2s;
        background-color: $lightGray;
        color: $mediumGray;

        &_clickable {
          background-color: white;
          color: $black;

          &:hover {
            cursor: pointer !important;
            border-color: $darkBlue;
          }

          &:active {
            background-color: rgba($darkBlue, 0.6);
            color: $white;
          }
        }

        &_active {
          background-color: $darkBlue;
          border-color: $darkBlue;
          color: $white;
          cursor: default !important;
        }

        &:focus {
          outline: none !important;
        }

        svg {
          @include svg-sizer(16px);
        }
      }
    }
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;

    &_limiter-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &_buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}
