.icon-button-mini-container {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;

  &_button {
    background-color: $white;
    border-radius: 50%;
    cursor: pointer;
    @include flex(row, center, center);
    transition: all 0.2s;
    border: 1px solid rgba($mediumGray, 0.5);

    &:hover {
      //background-color: rgba($lightGray, 1);
      cursor: pointer;
      border-color: $darkBlue;

      .icon-button-mini-container_button_icon {
        color: $darkBlue !important;
      }
    }

    &:active {
      background-color: rgba($lightGray, 0.4);
    }

    &_icon {
      @include svg-sizer(55%);
      transition: all 0.2s;
      color: rgba($mediumGray, 0.8);
    }
  }
}

