.icon-with-label {
  @include flex(row);
  align-items: center;

  &_icon {
    @include svg-sizer(1.1em);
    margin-right: 10px;
    color: $darkGray;
  }

  &_label {
    font-size: 0.95em;
    color: $mediumGray;
  }
}
