.bundles-item-priority-cell {
  &_label-container {
    margin-right: 0.75rem;
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }

  &_controls-container {
    div {
      display: flex;

      svg {
        @include svg-sizer(1.2em);
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
