$frame-one-global-loading-size: 14vw;
$frame-one-global-loading-min-size: 140px;

.loading-manager {
  @include flex(row, center, center);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  background-color: rgba(black, 0.6);

  &_svg {
    width: $frame-one-global-loading-size;
    height: $frame-one-global-loading-size;

    max-width: $frame-one-global-loading-size;
    max-height: $frame-one-global-loading-size;

    min-width: $frame-one-global-loading-min-size;
    min-height: $frame-one-global-loading-min-size;
  }
}
