$company-card-padding: 1.5rem;
$company-card-border-color: rgba($mediumGray, 0.4);

.company-card {
  background-color: $white;
  border: 1px solid $company-card-border-color;

  &_top {
    @include flex(row);
    padding: $company-card-padding;

    &_left {
      width: 40%;
    }

    &_right {
      @include flex(row, flex-end);
      width: 60%;
    }
  }

  &_divider {
    height: 1px;
    width: 100%;
    background-color: $company-card-border-color;
  }

  &_bottom {
    padding: $company-card-padding;

    &_title {
      @extend .miriam-libre_regular;
      color: $darkPurple;
    }

    &_metrics-container {
      margin-top: calc(#{$company-card-padding} / 3);
      width: 100%;
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: calc(#{$company-card-padding} / 3);

      &_metric {
        width: 50%;
      }
    }
  }
}
