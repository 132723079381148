.admin-info {
  p {
    font-size: 0.9em;
    margin-bottom: 0;
  }

  &_name {
    @extend .miriam-libre_bold;
  }
}
